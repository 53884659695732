'use client'
import { TRIPS_HEADINGS, VEHICLE_HEADINGS } from "../../utils/constants";
import TableHeader from "../../components/table_header";
import { Trip } from "../../utils/types";
import { useNavigate } from "react-router-dom";
import { dateFormat, formatNumber } from "../../utils/utils";


const TripsTable = (props: any) => {
 const navigate = useNavigate()
  return (
    
      <table className="w-full text-left p-2 mx-auto border">
        <TableHeader data={TRIPS_HEADINGS} />
        <tbody className="w-full text-sm">
          {props.data && props.data.length > 0 ? props.data.map((row:Trip) => {
            return (
              <tr key={row.id} className="text-sm border-b border-b-gray-200 py-8 hover:bg-gray-100 cursor-pointer px-2 items-start" onClick={()=>{navigate(`/trips/view/${row.id}`,{state:{trip:row}})}}>
                <td className="border-bottom px-1 py-2">{row.vehicle}
                </td>
                <td className="border-bottom px-1 py-2">{`${row.nameOfDriver}`}
                </td>
                {/* <td className="border-bottom px-1 py-2">{row.nameOfRequestor}
                </td> */}
                <td className="border-bottom px-1 py-2">{`${row.start_location}`}</td>
                <td className="border-bottom px-1 py-2">{row.destination}
                </td>
                <td className="border-bottom px-1 py-2 text-right">{row.end_mileage ? formatNumber(row.end_mileage - row.start_mileage): 'N/A'}
                </td>
                <td className="border-bottom px-1 py-2">{row.project}
                </td>
                <td className="border-bottom px-1 py-2">{row.fundcode}
                </td>
                <td className="border-bottom px-1 py-2">{dateFormat(row.date_created)}
                </td>
                <td className="border-bottom px-1 py-2">{row.status}
                </td>
                
              </tr>
            )
          }):<tr><td className="my-4 text-1xl text-center" colSpan={9}> No Trips</td></tr>}
        </tbody>
      </table>
      )

};
export default TripsTable;
