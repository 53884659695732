import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import { useState, FormEvent } from "react";
import { Fundcode, IProjectDetail } from "../../utils/types";
import { dateFormat } from "../../utils/utils";

const FundcodeForm = (props:any)=>{
    const [open,setOpen] = useState<boolean>(props.state)
    const [fundcode,setFundcode] = useState<Fundcode|null>(props.data)

    const handleClose = ()=>{
        setOpen(false);
        props.onClose()
    }
 const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    const e = event as unknown as FormEvent<HTMLFormElement>
    const formData = new FormData(e.currentTarget);
    let formJson = Object.fromEntries((formData as any).entries());
    if(fundcode?.id) formJson.id = fundcode?.id;
    formJson.expiration = Date.parse(formJson.expiration)/1000
    props.onSubmit(formJson)
    handleClose();
  }
  const handleFocus=(e:React.FocusEvent)=>{
    e.preventDefault();
    const target_id = e.currentTarget.id;
    const target = document.getElementById(target_id);
    target?.setAttribute('type','date');

  }
  const handleBlur=(e:React.FocusEvent<HTMLInputElement>)=>{
    e.preventDefault();
    const target = e.currentTarget;
    const val = e.currentTarget.value;
    target?.setAttribute('type','text');
    target.value = val

  }
    return (<>
       <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: handleFormSubmit
        }}
      >
        <DialogTitle>{fundcode ? 'Update Fundcode':'Add New Fundcode'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Complete this form to ${fundcode ? 'update':'create'} a new fund code`}
          </DialogContentText>
          <label>Select Project</label>
          <select
            autoFocus
            required
            id="project"
            name="project"
            defaultValue={fundcode?.project}
            className="form-control"><option>---</option>
            {props.projects.map((p:IProjectDetail)=>{
                return <option key={p.id} value={p.code}>{p.code}</option>
            })}</select>
           <label>Code</label>
          <input
            autoFocus
            required
            id="code"
            name="code"
            type="text"
            defaultValue={fundcode?.code}
            className="form-control"
            placeholder="Enter fund code"
          />
           <label>Expiration Date</label>
          <input
            required
            id="expiration"
            name="expiration"
            onFocus={handleFocus}
            onBlur={handleBlur}
            type={fundcode?.expiration ? 'text':'date'}
            className="form-control"
            defaultValue={dateFormat(fundcode?.expiration!)}
          />
          
          <label>Activities <small><i>Separate with comma</i></small></label>
          <input
            autoFocus
            required
            id="activities"
            name="activities"
            type="text"
            defaultValue={fundcode?.activities}
            placeholder="Enter activities"
            className="form-control"
          />
         
        </DialogContent>
        <DialogActions>
          <button onClick={handleClose} className="text-accent">Cancel</button>
          <button type="submit" className="bg-green-700">Save</button>
        </DialogActions>
      </Dialog>
    </>);
}
export default FundcodeForm