import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import { useState, FormEvent } from "react";
import { getCityNames } from "postcodes-tz";
import { SUPPLIER_SERVICES } from "../../utils/constants";
import { ISupplier } from "../../utils/types";

const SupplierForm = (props:any)=>{
    const [open,setOpen] = useState<boolean>(props.state)
    const [supplier,setSupplier] = useState<ISupplier|null>(props.data)

    const handleClose = ()=>{
        setOpen(false);
        props.onClose()
    }
 const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    const e = event as unknown as FormEvent<HTMLFormElement>
    const formData = new FormData(e.currentTarget);
    let formJson = Object.fromEntries((formData as any).entries());
    if(supplier) formJson.id = supplier.id;
    props.onSubmit(formJson)
    handleClose();
  }
   
    return (<>
       <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: handleFormSubmit
        }}
      >
        <DialogTitle>{supplier ? 'Update Supplier': 'Add New Supplier'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Complete this form to ${supplier ? 'update': 'create'} a new supplier`}
          </DialogContentText>
          <label>Choose Product/Service</label>
           <select
            required
            id="service"
            name="service"
            defaultValue={supplier?.service}
            className="form-control"><option>--</option>
            {SUPPLIER_SERVICES.map((c:string)=>{
                return <option key={c} value={c}>{c}</option>
            })}
            </select>
          <label>Company Name</label>
          <input
            autoFocus
            required
            id="name"
            name="name"
            type="text"
            defaultValue={supplier?.name}
            className="form-control"
            placeholder="Enter supplier name"
          />
          <label>TIN Number</label>
          <input
            autoFocus
            required = {props.defaultLocation != null}
            id="tin"
            name="tin"
            type="text"
            defaultValue={supplier?.tin}
            placeholder="Enter TIN number"
            className="form-control"
          />
          <label>Select Region</label>
           <select
            required
            id="region"
            name="region"
            defaultValue={props.defaultLocation}
            className="form-control"><option>--</option>
            {getCityNames('asc').map((c:string)=>{
                return <option key={c} value={c}>{c}</option>
            })}
            </select>
            
        </DialogContent>
        <DialogActions>
          <button onClick={handleClose} className="text-accent">Cancel</button>
          <button type="submit" className="bg-green-700">Save</button>
        </DialogActions>
      </Dialog>
    </>);
}
export default SupplierForm