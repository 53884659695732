import TableHeader from "../../components/table_header"
import { DEPARTMENTS_HEADINGS } from "../../utils/constants"
import { IDepartmentDetail } from "../../utils/types"

const DepartmentTable = (props:any)=>{
    const handleClick = (e: React.MouseEvent<HTMLTableRowElement>, dept: IDepartmentDetail)=>{
          props.handleSelect(dept)
        }
    return (
    
        <table className="w-full text-left p-2 mx-auto border">
          <TableHeader data={DEPARTMENTS_HEADINGS} />
          <tbody className="w-full text-sm">
            {props.data && props.data.map((row:IDepartmentDetail) => {
              return (
                <tr key={row.id} className="text-sm border-b border-b-gray-200 py-8 hover:bg-gray-100 cursor-pointer px-2 items-start" onClick={(e)=>{handleClick(e,row)}}>
                  <td className="border-bottom px-1 py-2">{row.code}
                  </td>
                  <td className="border-bottom px-1 py-2">{`${row.description}`}
                  </td>
                  {/* <td className="border-bottom px-1 py-2">{row.nameOfRequestor}
                  </td> */}
                  <td className="border-bottom px-1 py-2">{`${row.region}`}</td>
                  <td className="border-bottom px-1 py-2">{row.nameOfAdmin}
                  </td>
                 
                  
                </tr>
              )
            })}
          </tbody>
        </table>
        )
}
export default DepartmentTable