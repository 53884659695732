

// export const BASE_URL = 'http://localhost:8009'
export const BASE_URL = 'https://api.cvms.care.or.tz'
export const NOTIFICATIONS_HEADINGS = ["Date", "Title", "Content", "Status"]
export const REQUESTS_HEADINGS = ["Request Date", "Request type", "Requestor", "Status", "Project", "Fund Code", "Actions"]
export const USERS_HEADINGS = ["Username", "E-mail", "Phone", "Name", "Position", "Department", "Region", "Status"]
export const VEHICLE_HEADINGS = ["Registration", "Make", "Year", "Mileage", "Next Service Mileage", "Donnor", "Location", "Status"]
export const TRIPS_HEADINGS = ["Vehicle", "Driver", "From", "To", "Distance (Km)", "Project", "Fund Code", "Date", "Status"]
export const DEPARTMENTS_HEADINGS = ["Code", "Description", "Region", "Admin"]
export const PROJECTS_HEADINGS = ["Name","Code", "Donnor", "Coordinator", "Department","status"]
export const FUNDCODES_HEADINGS = ["Code", "Project", "Expiration","Activities","status"]
export const SUPPLIER_HEADINGS = ["Name", "Service", "Region","TIN"]
export const VEHICLE_FUEL_REPORT_HEADINGS = ["Vehicle","Fuel (Lt)","Distance (Km)","Cost (Tsh)","Consumption (Km/Lt)"]
export const VEHICLE_MAINTENANCE_REPORT_HEADINGS = ["Vehicle","Cost (Tsh)","Distance (Km)","Performance (Tsh/Km)"]
export const SMS_REPORT_HEADINGS = ["Month","Year","Count","Cost (Tsh)"]
export const enum USER_POSITIONS {
    ADMIN = 'Administrator',
    SUPER_ADMIN = 'Super Admin',
    COORDINATOR = 'Project Coordinator',
    DIRECTOR = 'Director',
    STAFF = 'Staff',
    DRIVER = 'Driver'
}
export const enum USER_POSITION_ID {
    ADMIN = 1,
    SUPER_ADMIN = 6,
    COORDINATOR = 4,
    DIRECTOR = 3,
    STAFF = 5,
    DRIVER = 2
}
export const enum REQUESTS_STATUS {
    PENDING = 'Pending',
    APPROVED = 'Approved',
    AUTHORIZED = 'Authorized',
    REJECTED = 'Rejected',
    DELETED = 'Deleted',
    CLOSED = 'Closed',
    CANCELLED = 'Cancelled'
}
export const enum REQUESTS_STATUS_ID {
    PENDING = 0,
    APPROVED = 1,
    AUTHORIZED = 2,
    REJECTED = 4,
    CANCELLED = 5,
    CLOSED = 3
}

export const SERVICE_TYPES = [
    {type: 2,text:'Normal Car Service'},{type: 3,text:'Full Car Service'},{type: 4,text:'Repair Service'}
]
export const enum REQUEST_TYPES {
    VEHICLE = 0,
    FUEL=1,
    NORMAL_SERVICE=2,
    FULL_SERVICE=3,
    REPAIR=4
}
export const SERVICE_PARTS = [
    { id: 1, name: "Fuel Filter" },
    { id: 2, name: "Air Filter" },
    { id: 3, name: "Radiator Coolant" },
    { id: 4, name: "Engine Oil" },
    { id: 5, name: "Brake Fluid" },
    { id: 6, name: "Gearbox Oil" },
    { id: 7, name: "Power Steering Fluid" },
    { id: 8, name: "Differential Oil" },
    { id: 9, name: "Clutch Fluid" },
    { id: -1, name: "Other" },
  ]

export const SETTINGS_TABS = [
    {id: 1, title: "Departments"},{id: 2, title: "Projects"},{id: 3, title: "Fund Codes"},{id: 4, title: "Suppliers"},{id: 5, title: "Account Settings"}
]
export const REPORT_TABS = [
    {id: 1, title: "Fuel Consumption"},{id: 2, title: "Maintenance Cost"},{id: 3, title: "SMS and Notifications"}
]
export const SUPPLIER_SERVICES = [
    "Fuel","Maintenance"
]