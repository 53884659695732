import TableHeader from "../../components/table_header"
import { SUPPLIER_HEADINGS } from "../../utils/constants"
import { ISupplier } from "../../utils/types"

const SupplierTable = (props:any)=>{
     const handleClick = (e: React.MouseEvent<HTMLTableRowElement>, supplier: ISupplier)=>{
          props.handleSelect(supplier)
        }
    return (
    
        <table className="w-full text-left p-2 mx-auto border">
          <TableHeader data={SUPPLIER_HEADINGS} />
          <tbody className="w-full text-sm">
            {props.data && props.data.map((row:ISupplier) => {
              return (
                <tr key={row.id} className="text-sm border-b border-b-gray-200 py-8 hover:bg-gray-100 cursor-pointer px-2 items-start" onClick={(e)=>{handleClick(e,row)}}>
                  <td className="border-bottom px-1 py-2">{row.name}
                  </td>
                  <td className="border-bottom px-1 py-2">{`${row.service}`}
                  </td>
                  <td className="border-bottom px-1 py-2">{`${row.region}`}</td>
                  <td className="border-bottom px-1 py-2">{row.tin}
                  </td>
                 
                  
                </tr>
              )
            })}
          </tbody>
        </table>
        )
}
export default SupplierTable