import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/sidebar"
import { getStoredUserData, groupByArray, isAdmin, isAuthenticated } from "../../utils/utils";
import Spinner from "../../components/spinner";
import { FocusEvent, ChangeEvent, useEffect, useState } from "react";
import { ApiClient } from "../../utils/apiclient";
import { Fundcode, IMaintenanceRequest, IProjectDetail, ISupplier, IUser, IUserData, User, Vehicle } from "../../utils/types";
import { Formik } from "formik";
import * as yup from 'yup'
import { RequestService } from "../../utils/services/request.service";
import { toast, ToastContainer } from "react-toastify";
import { REQUESTS_STATUS_ID, SERVICE_PARTS, SERVICE_TYPES } from "../../utils/constants";
import Checkbox from "../../components/checkbox";
import TopStrip from "../../components/top_strip";
import SupplierForm from "../settings/supplier_form";

const ServiceRequestForm = (_props: any) => {
    const apiClient = new ApiClient()
    const requestService = new RequestService(apiClient)
    const userData = getStoredUserData();
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [requestors, setRequestors] = useState<User[]>([])
    const [fRequestors, setFRequestors] = useState<User[]>([])
    const [projects, setProjects] = useState<IProjectDetail[]>([])
    const [fundcodes, setFundcodes] = useState<Fundcode[]>([])
    const [activities, setActivities] = useState<string[]>([])
    const [vehicles, setVehicles] = useState<Vehicle[]>([])
    const [fVehicles, setFVehicles] = useState<Vehicle[]>([])
    const [selectedVehicle, setSelectedVehicle] = useState<Vehicle>()
    const [selectedDriver, setSelectedDriver] = useState<User | IUser | IUserData | null | undefined>();
    const [suppliers, setSuppliers] = useState<ISupplier[]>([])
    const [fSuppliers, setFSuppliers] = useState<ISupplier[]>([])
    const [hasExtra, setHasExtra] = useState<boolean>(false)
    const [serviceType, setServiceType] = useState<number>(2)
    const [selectedParts, setSelectedParts] = useState<string[]>([])
    const [lastRequest, setLastRequest] = useState<IMaintenanceRequest | null>(null)
    const [history, setHistory] = useState<any[]>([])
    const [totalDistance, setTotalDistance] = useState<number>(0)
    const [totalCost, setTotalCost] = useState<number>(0)
    const [open,setOpen] = useState<boolean>(false)
    const [selectedSupplier,setSelectedSupplier] = useState<ISupplier| null>(null)

   
    const isUserAdmin = isAdmin(userData)
    const filterUsers = (filter: string) => {
        if (filter && filter.length > 0) {
            filter = filter.toLowerCase();
            const result = requestors.filter((u: User | IUser) => {
                return u.fname.toLowerCase().includes(filter) || u.lname.toLowerCase().includes(filter)
            });
            setFRequestors(result)
            if(result.length > 0) setSelectedDriver(result[0])
        }
        else setFRequestors(requestors)
    }
    const handleExtra = (hasExtra: boolean) => {
        setHasExtra(hasExtra)
    }
    const handleCostChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.value && e.currentTarget.value.length > 0) {
            const cost = Number(e.currentTarget.value.trim())
            setTotalCost(cost)
        }
        else setTotalCost(0)
    }
    const loadVehicleAndDrivers = async () => {
        const [drivers, vehicles] = await Promise.all([apiClient.getAvailableDrivers(), apiClient.getAvailableVehicles()]);
        const driver = isUserAdmin && drivers && drivers.length > 0 ? drivers[0]:userData
        if (isUserAdmin) {
            setRequestors(drivers);
            setFRequestors(drivers)
            setVehicles(vehicles)
            setFVehicles(vehicles)
            
        }
        else {
            setRequestors(drivers.filter((d: User) => d.id == userData?.id));
            setFRequestors(drivers.filter((d: User) => d.id == userData?.id))
            setVehicles(vehicles.filter((v: Vehicle) => v.location == userData?.location))
            setFVehicles(vehicles.filter((v: Vehicle) => v.location == userData?.location))
        }
        setSelectedDriver(driver)
        loadSuppliers(driver)
    }

    const loadProjects = async () => {
        let result = []
        let projects = await apiClient.getAllProjects();
        if (projects && projects.status == 200) {
            result = projects.data.projects
        }
        setProjects(result);
        if (result.length > 0) {
            loadFundcodes(result[0].code)
        }

    }
    const loadSuppliers = async (user: IUser | IUserData | null | User | undefined) => {
        let data = {
            location: user && user.location ? user.location : null
        }
        const axios = await apiClient.getSuppliers(data)
        let result = axios.data.suppliers;
        setSuppliers(result)
        if (serviceType) {
            result = result.filter((r: ISupplier) => r.service == 'Maintenance')
        }
        if(selectedDriver){
            result = result.filter((r: ISupplier) => r.region == user?.location)
        }
        setFSuppliers(result)
    }
    const handleFundcodeChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const code = e.currentTarget.value;
        const fundcode = fundcodes.find((f: Fundcode) => f.code == code);
        setActivities(fundcode?.activities!!)
    }
    const loadFundcodes = async (projectCode: string) => {
        let result = []
        const fundcodes = await apiClient.getProjectFundcodes(projectCode)
        if (fundcodes && fundcodes.status == 200) {
            result = fundcodes.data.fundcodes
        }
        setFundcodes(result);
    }
    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        filterUsers(e.currentTarget.value)
    }
    const loadData = async () => {
        Promise.all([loadProjects(), loadVehicleAndDrivers()])
    }
    const handleProjectChange = (e: ChangeEvent<HTMLSelectElement>) => {
        if (e.currentTarget.value) {
            loadFundcodes(e.currentTarget.value);
            setActivities([])
        }
        else {
            setFundcodes([])
            setActivities([])
        }
    }
    const handleDriverSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        const driver = requestors.find((u: User) => u.id == Number(e.currentTarget.value)) as unknown as IUser
        const filterVehicles = vehicles;//.filter((v: Vehicle) => v.location == driver?.location)
        setSelectedDriver(driver)
        loadSuppliers(driver);
        setFVehicles(filterVehicles)
    }
    const handleVehicleChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const vehicle = vehicles.find((v: Vehicle) => v.pnumber == e.currentTarget.value)
        setSelectedVehicle(vehicle)

        if (vehicle) {
            getLastRequest(vehicle.pnumber)

        }
    }
    const handleServiceChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const serviceId = Number(e.currentTarget.value)
        setServiceType(serviceId)
        setFSuppliers(suppliers.filter((s: ISupplier) => s.service == 'Maintenance'))

    }
    const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
        const input = document.activeElement as unknown as HTMLInputElement;
        input.type = 'date';

    }
    const getLastRequest = async (vehicle: string) => {
        getVehicleHistory(vehicle, serviceType)
    }
    let scheme = {
        vehicle: yup.string().required('Please select a vehicle'),
        supplier: yup.number().required().min(0,"Please select valid supplier"),
        cost: yup.number().required('Please enter an estimated cost').min(0, 'Cost cannot be 0 or less'),
        required_date: yup.date().required('Please provide required date'),
        fundcode: yup.string(),
        project: yup.string(),
        service_type: yup.string().required(),
        
    }
    if (serviceType in [3, 4]) {
        scheme.project = yup.string().required();
        scheme.fundcode = yup.string().required()
    }
    //validation schema
    const formValidationSchema = yup.object(scheme)

    const getVehicleHistory = async (vehicle: string, requestType: number) => {
        const axios = await apiClient.getVehicleHistory(vehicle, requestType);
        const history = axios.data.history;
        prepareHistoryData(history)

    }
    const prepareHistoryData = (history: any[]) => {
        if (history && history.length > 0) {
            let newHistory = groupByArray(history, "fundcode");
            let mappedHistory = newHistory.map((h: any) => {
                let hist = {
                    ...h,
                    distance: h.values.reduce(
                        (a: number, b: any) => a + b.distance,
                        0
                    ),
                    project: h.values[0].project
                }
                return hist;
            });
            let totalDistance = mappedHistory.reduce((a: number, b: any) => a + b.distance, 0);
            setHistory(mappedHistory.map((h: any) => {
                return {
                    ...h,
                    ratio: totalDistance > 0 ? (h.distance / totalDistance).toFixed(3) : 0
                }
            }));
            setTotalDistance(totalDistance)
        } else {
            setHistory([]);
            setTotalDistance(0)
        }
    }
    const formHandler = async (values: any, errors: any) => {
        const keys: string[] = Object.keys(values);
        const activities: string[] = keys.filter((key: string) => key.includes('cb')).map((key: string) => key.substring(2));
        let start_time = `${values.required_date} ${values.start_time ? values.start_time + ':00' : '00:00:00'}`
        const data: Partial<IMaintenanceRequest> = {
            admin: userData?.department_detail?.admin!,
            requestor: selectedDriver?.id,
            type: serviceType,
            requestType: serviceType,
            vehicle: values.vehicle,
            mileage: selectedVehicle?.current_mileage!,
            supplier: values.supplier,
            cost: totalCost,
            project: values.project,
            fundcode: values.fundcode,
            account: values.account,
            activity: activities.join(','),
            source: values.source,
            category: values.category,
            status: REQUESTS_STATUS_ID.PENDING,
            approver: projects.find((project: IProjectDetail) => project.code == values.project)?.coordinator!,
            start_time: Date.parse(start_time) / 1000,
            description: serviceType == 2 ? 'Service mileage reached' : (serviceType == 3 ? values.reason : values.problem),
            nameOfRequestor: `${userData?.fname} ${userData?.lname}`,
            parts: serviceType == 2 ? selectedParts.concat(values.extra.split(",")).join(",") : values.more_parts,
            // distribution: {
            //     fundcode: history.length > 0 ? history.map((h: any) => h.key).join(',') : values.fundcode,
            //     project: history.length > 0 ? history.map((h: any) => h.project).join(',') : values.project,
            //     distribution: history.length > 0 ? history.map((h: any) => h.ratio).join(',') : null
            // }
        }
        console.log("🚀 ~ formHandler ~ data:", data)

        try {
            setIsLoading(true)
            const response = await requestService.createServiceRequest(data)
            console.log("🚀 ~ formHandler ~ response:", response.data.response)

            toast.info(response.data.response, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
            setTimeout(() => {
                navigate(-1)
            }, 5000)
        }
        catch (e: any) {
            toast.error(e, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        }
        finally {
            setIsLoading(false)
        }
    }
    const handleCheckChange = (selected: boolean, text: string) => {
        if (text != 'Other') {
            let parts = selectedParts;
            if (selected) parts = parts.concat([text]);
            else parts = parts.filter((p: string) => p != text)
            setSelectedParts(parts)
        }


    }
    const handleSupplierChange = (e:ChangeEvent<HTMLSelectElement>)=>{
        const selection = Number(e.currentTarget.value);
        if(selection === 0) setOpen(true);
        else setOpen(false)
    }
    const submitSupplierForm=async (data:any)=>{
        const axios = await apiClient.createSupplier(data)
        console.log("🚀 ~ submitSupplierForm ~ axios:", axios.data.response)
        const message = axios.status == 201 ? axios.data.response?.message : "Oops! Something went wrong. Please contact support"
        toast.info(message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        })
        setOpen(false);
        loadSuppliers(selectedDriver)
        setSelectedSupplier(axios.data.response?.supplier)
    }
    useEffect(() => {
        if (isAuthenticated()) loadData();
        else navigate('/login', { state: { targetPath: '/requests/fuel/new' } })
    }, [selectedSupplier])
    return (<>
        <div className="w-full flex justify-end items-center bg-gray-100 pe-4">
            <TopStrip user={userData} />
        </div>
        <ToastContainer />
        <main className="w-full flex bg-white min-h-screen">
            <div className="md:w-2/12 flex flex-col items-start text-center justify-center">
                <Sidebar notificationCount={[].length} user={userData} active={1} />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 f
            </div>
            <div className="md:w-9/12 mx-auto space-y-4 mt-2">
            {open  ? <SupplierForm onSubmit={submitSupplierForm} state={open} onClose={() => { setOpen(false) }} defaultLocation={selectedDriver? selectedDriver.location: 'Dar es salaam'}/> : null}
                <Formik validationSchema={formValidationSchema} initialValues={{ problem: '', service_type: '', supplier: selectedSupplier?.id, vehicle: '', required_date: '', cost: 0, requestor: isUserAdmin ? selectedDriver?.id : userData?.id, mileage: selectedVehicle?.current_mileage, extra: "", more_parts: "", reason: '', start_time: "", project: "", fundcode: fundcodes.length > 0 ? fundcodes[0].code : '' }} onSubmit={formHandler}>
                    {({ handleSubmit, handleChange, values, errors, touched }) => (
                        <form
                            name="vehicle-request-form"
                            id="vehicle-request-form"
                            className="center-self border-all-main"
                            onSubmit={handleSubmit}
                        >
                            <p className="text-2xl font-bold my-3 text-start">Maintenance Request</p>

                            <div className="mt-5 w-100 flex items-center justify-start flex-wrap space-x-2">
                                {isUserAdmin ? <div className="text-start w-5/12">
                                    <p className="text-textDefault">Search Staff</p>
                                    <input
                                        readOnly={!isUserAdmin}
                                        disabled={!isUserAdmin}
                                        onChange={(e)=>{
                                            handleChange(e);
                                            handleSearch(e)
                                        }}
                                        className="form-control "
                                        type="text"
                                        name="search_staff"
                                        id="search_staff"
                                        // value={searchValue}
                                        placeholder='Enter staff name'
                                    />
                                </div> : null}
                                <div className="text-start w-5/12">
                                    <p className="text-textDefault">Requestor's Name  <span className="text-red-500">*</span></p>
                                    <select
                                        className="form-control "
                                        name="requestor"
                                        id="requestor"
                                        onChange={(e) => {
                                            handleChange(e);
                                            handleDriverSelect(e)
                                        }}
                                        value={selectedDriver?.id}
                                    >
                                        {fRequestors.map((u) => {
                                            return (
                                                <option key={u.id} value={u.id} >
                                                    {u.fname + " " + u.lname}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>

                            </div>

                            <div className="mt-5 w-100 flex items-start justify-start flex-wrap space-x-2">
                                <div className="text-start w-3/12">
                                    <p className="text-textDefault">Select Service Type  <span className="text-red-500">*</span></p>
                                    <select
                                        className="form-control "
                                        name="service_type"
                                        id="service_type"
                                        onChange={(e) => {
                                            handleChange(e);
                                            handleServiceChange(e)
                                        }}
                                        defaultValue={selectedVehicle?.pnumber}
                                    ><option>--</option>
                                        {SERVICE_TYPES.map((v) => {
                                            return (
                                                <option key={v.type} value={v.type} >
                                                    {v.text}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <small className="text-red-500">{errors && errors.service_type && touched ? errors.service_type : null}</small>
                                </div>
                                <div className="text-start w-3/12">
                                    <p className="text-textDefault">Select Vehicle  <span className="text-red-500">*</span></p>
                                    <select
                                        className="form-control "
                                        name="vehicle"
                                        id="vehicle"
                                        onChange={(e) => {
                                            handleChange(e);
                                            handleVehicleChange(e)
                                        }}
                                        defaultValue={selectedVehicle?.pnumber}
                                    ><option>--</option>
                                        {fVehicles.map((v) => {
                                            return (
                                                <option key={v.id} value={v.pnumber} >
                                                    {v.pnumber}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <small className="text-red-500">{errors && errors.vehicle && touched ? errors.vehicle : null}</small>
                                </div>
                                <div className="text-start w-3/12">
                                    <p className="text-textDefault">Vehicle Mileage</p>
                                    <input
                                        disabled
                                        onChange={handleChange}
                                        className="form-control "
                                        type="text"
                                        name="mileage"
                                        id="mileage"
                                        defaultValue={selectedVehicle?.current_mileage}
                                        placeholder='Enter vehicle mileage'
                                    />
                                </div>
                            </div>
                            <div className="mt-5 md:w-10/12 flex items-start justify-start space-x-2">
                                {serviceType == 2 ?
                                    <fieldset className="mt-2 w-100 px-4 flex flex-col justify-start items-start space-y-2 border py-4">
                                        <legend className="px-4 text-start">Select Parts</legend>
                                        <div className="md:w-10/12 m-2 flex justify-start items-start flex-wrap">
                                            {SERVICE_PARTS.map((p) => {
                                                return <Checkbox onChange={handleCheckChange} key={p.id} text={p.name} id={p.id} hasExtra={(hasExtra: boolean) => handleExtra(hasExtra)}>{p.name}</Checkbox>
                                            })}
                                        </div>
                                        {hasExtra ? <input type="text" id="extra" name="extra" placeholder="Enter more parts" className="form-control m-2 md:w-8/12" onChange={handleChange} /> : null}
                                    </fieldset> : null}
                                {serviceType == 3 ? <fieldset className="mt-2 w-100 px-4 flex flex-col justify-start items-start space-y-2 border py-4">
                                    <legend className="px-4 text-start">Select Parts</legend>
                                    <div className="md:w-10/12 m-2 flex justify-start items-start flex-wrap">
                                        <label>Parts <small><i>(separate with comma</i></small>)</label>
                                        <input type="text" id="more_parts" name="more_parts" className="form-control" placeholder="air filter, engine oil, gearbox oil,..." onChange={(e) => {
                                            handleChange(e);
                                        }} />
                                    </div>
                                    <div className="md:w-10/12 m-2 flex justify-start items-start flex-wrap">
                                        <label>Reason</label>
                                        <textarea id="reason" name="reason" className="form-control" placeholder="Give a reason for request a full car service" onChange={handleChange}>

                                        </textarea>
                                    </div>
                                </fieldset> : null}
                                {serviceType == 4 ? <fieldset className="mt-2 w-100 px-4 flex flex-col justify-start items-start space-y-2 border">
                                    <legend className="px-4 text-start">Select Parts</legend>
                                    <div className="md:w-10/12 m-2 flex justify-start items-start flex-wrap">
                                        <label>Parts <small><i>(separate with comma</i></small>)</label>
                                        <input type="text" id="more_parts" name="more_parts" className="form-control" placeholder="air filter, engine oil, gearbox oil,..." onChange={handleChange} />
                                    </div>
                                    <div className="md:w-10/12 m-2 flex justify-start items-start flex-wrap">
                                        <label>Describe Problem</label>
                                        <textarea id="problem" name="problem" className="form-control" placeholder="Describe briefly the problem that needs to be fixed" onChange={handleChange}>

                                        </textarea>
                                    </div>
                                </fieldset> : null}
                            </div>
                            {selectedDriver &&
                            <div className="mt-5 w-100 flex items-start justify-start flex-wrap space-x-2">
                                <div className="text-start w-4/12">
                                    <p className="text-textDefault">Select Supplier <span className="text-red-500">*</span></p>
                                    <select
                                        className="form-control "
                                        name="supplier"
                                        id="supplier"
                                        onChange={(e)=>{
                                            handleChange(e);
                                            handleSupplierChange(e)
                                        }}
                                        value={values.supplier}
                                    ><option value={-1}>--</option>
                                        {fSuppliers.map((v) => {
                                            return (
                                                <option key={v.id} value={v.id} >
                                                    {v.name}
                                                </option>
                                            );
                                        })}
                                    <option value={0} className="text-2xm">-- add new supplier --</option>
                                    </select>
                                    <small className="text-red-500">{errors && errors.supplier && touched ? errors.supplier : null}</small>
                                </div>
                                <div className="text-start w-3/12">
                                    <p className="text-textDefault">Estimated Cost (TSH)  <span className="text-red-500">*</span></p>
                                    <input
                                        onChange={(e) => {
                                            handleChange(e);
                                            handleCostChange(e);
                                        }}
                                        className="form-control "
                                        type="number"
                                        name="cost"
                                        id="cost"
                                        defaultValue={values.cost}
                                        placeholder='Enter unit price in TSH'
                                    />
                                    <small className="text-red-500">{errors && errors.cost && touched ? errors.cost : null}</small>
                                </div>

                            </div>}
                            <div className="mt-5 w-100 flex items-start justify-start">
                                <fieldset className="flex p-4 justify-start items-start space-x-2 border md:w-10/12">
                                    <legend className="px-4 text-start">Charging Instructions</legend>
                                    <div className="text-start md:w-3/12">
                                        <p className="text-textDefault">Project <span className="text-red-500">*</span></p>
                                        <select required={history.length > 0 && serviceType in [3, 4]}
                                            onChange={(e) => {
                                                handleChange(e);
                                                handleProjectChange(e)
                                            }}
                                            className="form-control"
                                            name="project"
                                            id="project"
                                        >
                                            <option>--</option>
                                            {projects && projects.map((project: IProjectDetail) => {
                                                return <option key={project.id} value={project.code}>{project.code}</option>;
                                            })}
                                        </select>
                                        <small className="text-red-500">{errors && errors.project && touched ? errors.project : null}</small>
                                    </div>
                                    <div className="text-start md:w-3/12">
                                        <p className="text-textDefault">Fund Code <span className="text-red-500">*</span></p>
                                        <select required={history.length > 0 && serviceType in [3, 4]}
                                            id="fundcode"
                                            name="fundcode"
                                            className="form-control"
                                            onChange={(e) => {
                                                handleChange(e);
                                                handleFundcodeChange(e)
                                            }
                                            }
                                        >
                                            <option>--</option>
                                            {fundcodes.map((fcode) => {
                                                return <option key={fcode.id}>{fcode.code}</option>;
                                            })}
                                        </select>
                                        <small className="text-red-500">{errors && errors.fundcode && touched ? errors.fundcode : null}</small>
                                    </div>
                                    {activities && activities.length > 0 ?
                                        <div className="text-start md:w-5/12">
                                            <p className="text-textDefault">Activities</p>
                                            <div className="w-10/12 flex justify-start items-center space-x-2">
                                                {activities?.map((a) => {
                                                    return (
                                                        <div key={"lb" + a} className="md:w-3/12 flex justify-start items-start space-x-2">
                                                            <input
                                                                className="w-4 h-4 mt-4"
                                                                type="checkbox"
                                                                id={"cb" + a}
                                                                value={a}
                                                                onChange={handleChange}
                                                            />
                                                            <label>{a}</label>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div> : null}
                                </fieldset>

                            </div>

                            <div className="mt-5 w-100 flex items-center justify-even flex-wrap space-x-3">
                                <div className="text-start w-3/12">
                                    <p className="text-textDefault">Account</p>
                                    <input
                                        className="form-control "
                                        type="text"
                                        name="account"
                                        id="account"
                                        placeholder="Account" onChange={handleChange}
                                    />
                                </div>

                                <div className="text-start w-3/12">
                                    <p className="text-textDefault">Source</p>
                                    <input id="source" name="source" className="form-control" placeholder="Source" onChange={handleChange} />
                                </div>
                                <div className="text-start w-3/12">
                                    <p className="text-textDefault">Category</p>
                                    <input id="category" className="form-control" name="category" placeholder="Category" onChange={handleChange} />
                                </div>
                            </div>
                            <div className="mt-5 w-100 flex items-start justify-start space-x-2">

                                <div className="text-start w-5/12">
                                    <p className="text-textDefault">Required Date <span className="text-red-500">*</span></p>
                                    <input
                                        className="form-control w-50"
                                        type="date"
                                        name="required_date"
                                        id="required_date"
                                        onChange={handleChange}
                                        onFocus={handleFocus}
                                    />
                                    <small className="text-red-500">{errors && errors.required_date && touched ? errors.required_date : null}</small>
                                </div>
                                <div className="text-start w-5/12">
                                    <p className="text-textDefault">Time</p>
                                    <input
                                        onChange={handleChange}
                                        className="form-control"
                                        type="time"
                                        name="start_time"
                                        id="start_time"
                                    />

                                </div>
                            </div>


                            <div className="mt-5 flex justify-between items-center w-10/12">
                                {isLoading ? <Spinner className="spinner-sm mt-4" /> : (errors && Object.keys(errors).length > 0 ? null : <button type="submit" id="submit" name="submit" className=" bg-accent p-2 text-center hover:bg-accentDark">Submit</button>)}
                                <button type="button" className="bg-transparent border border-accent text-accent p-2 text-center" onClick={() => { navigate('/requests') }}>Cancel</button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </main>
    </>)
}
export default ServiceRequestForm