import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import { useState, FormEvent, ChangeEvent } from "react";
import { IDepartmentDetail, IFuelRequest, IUser } from "../../utils/types";
import { getCityNames } from "postcodes-tz";

const DepartmentForm = (props:any)=>{
    const [open,setOpen] = useState<boolean>(props.state)
    const [dept,setDept] = useState<IDepartmentDetail|null>(props.data)

    const handleClose = ()=>{
        setOpen(false);
        props.onClose()
    }
 const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    const e = event as unknown as FormEvent<HTMLFormElement>
    const formData = new FormData(e.currentTarget);
    let formJson = Object.fromEntries((formData as any).entries());
    if(dept?.id) formJson.id = dept.id;

    props.onSubmit(formJson)
    handleClose();
  }
   
    return (<>
       <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: handleFormSubmit
        }}
      >
        <DialogTitle>{dept ? `Update Department`:`Add New Department`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Complete this form to ${dept ? 'update':'create'} a new department`}
          </DialogContentText>
          <label>Department Code/ID</label>
          <input
            autoFocus
            required
            id="code"
            name="code"
            type="text"
            defaultValue={dept?.code}
            className="form-control"
            placeholder="Enter department code"
          />
          <label>Description</label>
          <input
            autoFocus
            required
            id="description"
            name="description"
            type="text"
            defaultValue={dept?.description}
            placeholder="Enter a description"
            className="form-control"
          />
          <label>Select Region</label>
           <select
            required
            id="region"
            name="region"
            defaultValue={dept?.region}
            className="form-control"><option>--</option>
            {getCityNames('asc').map((c:string)=>{
                return <option key={c} value={c}>{c}</option>
            })}
            </select>
            <label>Choose Department Admin</label>
           <select
            required
            id="admin"
            name="admin"
            defaultValue={dept?.admin}
            className="form-control"><option>--</option>
            {props.admins.map((c:IUser)=>{
                return <option key={c.id} value={c.id}>{`${c.fname} ${c.lname}`}</option>
            })}
            </select>
        </DialogContent>
        <DialogActions>
          <button onClick={handleClose} className="text-accent">Cancel</button>
          <button type="submit" className="bg-green-700">Save</button>
        </DialogActions>
      </Dialog>
    </>);
}
export default DepartmentForm