'use client'
import axios, { AxiosError, AxiosRequestConfig } from "axios"
import { IRequest, ILogin, IPaginationOptions, IUser, User, Vehicle, Trip, ApiResponse } from "./types";
import { BASE_URL } from "./constants";
export class ApiClient {
    ACCESS_TOKEN: string | null = null;
    constructor(accessToken?: string) {
        if (accessToken) this.ACCESS_TOKEN = accessToken
    }
    get = async (endpoint: string): Promise<ApiResponse> => {
        var headersConfig: AxiosRequestConfig = {
            headers: {
                ContentType: 'application/json',
                Authorization: this.ACCESS_TOKEN && this.ACCESS_TOKEN != '' ? `Bearer ${this.ACCESS_TOKEN}` : ''
            }
        }
        try {
            const response = await axios.get(`${BASE_URL}${endpoint}`, headersConfig);
            return {
                status: response.status,
                statusText: response.statusText,
                data: response.data
            }
        }
        catch (e: any) {
            const error = e as unknown as AxiosError;
            return {
                status: error.isAxiosError ? error.status! : 400,
                statusText: error.isAxiosError ? error.code! : 'Bad Request',
                data: error.response
            }
        }
    }

    put = async (endpoint: string, data: any, options?: any): Promise<ApiResponse> => {
        var config: any = {
            'Content-type': 'application/json'
        }
        if (this.ACCESS_TOKEN && this.ACCESS_TOKEN != '') {
            config.authorization = `Bearer ${this.ACCESS_TOKEN}`;
        }

        try {
            const response = await axios.put(`${BASE_URL}${endpoint}`, data, { headers: { config } });
            return {
                status: response.status,
                statusText: response.statusText,
                data: response.data
            }
        }
        catch (e: any) {
            const error = e as unknown as AxiosError;
            return {
                status: error.isAxiosError ? error.status! : 400,
                statusText: error.isAxiosError ? error.code! : 'Bad Request',
                data: error.response
            }
        }
    }
    patch = async (endpoint: string, data: any, options?: any): Promise<ApiResponse> => {
        var config: any = {
            'Content-type': 'application/json'
        }
        if (this.ACCESS_TOKEN && this.ACCESS_TOKEN != '') {
            config.authorization = `Bearer ${this.ACCESS_TOKEN}`;
        }
        try {
            const response = await axios.patch(`${BASE_URL}${endpoint}`, data, { headers: { config } });
            return {
                status: response.status,
                statusText: response.statusText,
                data: response.data
            }
        }
        catch (e: any) {
            const error = e as unknown as AxiosError;
            return {
                status: error.isAxiosError ? error.status! : 400,
                statusText: error.isAxiosError ? error.code! : 'Bad Request',
                data: error.response
            }
        }
    }
    post = async (endpoint: string, data: any, options?: any): Promise<ApiResponse> => {
        var config: any = {
            'Content-type': 'application/json'
        }
        if (this.ACCESS_TOKEN && this.ACCESS_TOKEN != '') {
            config.authorization = `Bearer ${this.ACCESS_TOKEN}`;
        }
        try {
            const response = await axios.post(`${BASE_URL}${endpoint}`, data, { headers: { config } });
            return {
                status: response.status,
                statusText: response.statusText,
                data: response.data
            }
        }
        catch (e: any) {
            const error = e as unknown as AxiosError;
            return {
                status: error.isAxiosError ? error.status! : 400,
                statusText: error.isAxiosError ? error.code! : 'Bad Request',
                data: error.response
            }
        }
    }

    login = async (path: string, body: ILogin): Promise<ApiResponse> => {
        const login = await this.post(path, body);
        return login;
    }

    getMenuActions = async (position: number): Promise<ApiResponse> => {
        const actions = await this.get(`/actions/${position}`);
        return actions;
    }

    getActiveUsers = async () => {
        const users = await this.get(`/active-users`);
        return users.data;
    }
    getUsers = async (options?: IPaginationOptions) => {
        const page = options && options.page ? options.page : 1;
        const take = options && options.take ? options.take : 20;
        const users = await this.get(`/users?page=${page}&take=${take}`);
        return users;
    }
    getUserDetails = async (userId: number) => {
        const user = await this.get(`/user/${userId}`);
        return user;
    }
    addUser = async (data: IUser) => {
        const axios = await this.post('/user', data);
        return axios;
    }
    updateUser = async (data: IUser, userId: number) => {
        console.log("🚀 ~ ApiClient ~ updateUser= ~ data:", data)
        if (data.password == "") {
            delete data.password
        }
        console.log("🚀 ~ ApiClient ~ updateUser= ~ data:", data)
        try {
            const axios = await this.patch(`/user/${userId}`, data);
            return {
                status: axios.status,
                statusText: axios.statusText,
                data: `${axios.data.response}. Please login for changes to reflect`
            }
        }
        catch (e: any) {
            return {
                status: 400,
                statusText: 'Bad Request',
                data: `Error: ${e}`
            }
        }

    }
    getVehicles = async () => {
        const vehicles = await this.get(`/vehicles`);
        return vehicles;
    }
    getVehicleDetails = async (id: number) => {
        const vehicle = await this.get(`/vehicles/${id}`);
        return vehicle;
    }
    createVehicle = async (body: Partial<Vehicle>): Promise<ApiResponse> => {

        try {
            const axios = await this.post(`/vehicles`, body);
            return {
                status: axios.status,
                statusText: axios.statusText,
                data: axios.data.response
            }
        }
        catch (e: any) {
            return {
                status: 400,
                statusText: 'Bad Request',
                data: `Error: ${e}`
            }
        }
    }
    updateVehicle = async (body: Partial<Vehicle>): Promise<ApiResponse> => {

        try {
            const axios = await this.put(`/vehicles/${body.id}`, body);
            return {
                status: axios.status,
                statusText: axios.statusText,
                data: axios.data.response
            }
        }
        catch (e: any) {
            return {
                status: 400,
                statusText: 'Bad Request',
                data: `Error: ${e}`
            }
        }
    }
    getAvailableVehicles = async (request?: IRequest): Promise<Vehicle[]> => {
        let availableVehicles: Vehicle[] = []
        try {
            const axios = await this.getVehicles();
            if (axios.status == 200) {
                const vehicles = axios.data.vehicles;
                    availableVehicles = vehicles.filter((vehicle: Vehicle) => vehicle.in_use !=3);
                if(request && request.type == 0) availableVehicles = vehicles.filter((vehicle: Vehicle) => vehicle.in_use == 0);
                return availableVehicles;
            }
        }
        catch (e: any) {
            console.log("🚀 ~ ApiClient ~ getAvailableVehicles= ~ e:", e)
            return availableVehicles;
        }
        return availableVehicles;
    }
    getTrips = async (options?: IPaginationOptions) => {
        const page = options && options.page || 1;
        const take = options && options.take || 20
        const trips = await this.get(`/trips?page=${page}&take=${take}`);
        return trips;
    }
    getTripDetail = async (tripId: number) => {
        const trips = await this.get(`/trip/${tripId}`);
        return trips;
    }
    getIncompleteTrips = async (options?: IPaginationOptions): Promise<ApiResponse> => {
        const page = options && options.page || 1;
        const take = options && options.take || 20
        try {
            const trips = await this.get(`/trips-incomplete?page=${page}&take=${take}`);
            return {
                status: trips.status,
                statusText: trips.statusText,
                data: trips.data
            }
        }
        catch (e: any) {
            return {
                status: 400,
                statusText: 'Bad Request',
                data: e
            }
        }

    }

    completeTrip = async (trip: Partial<Trip>): Promise<ApiResponse> => {
        let endpoint = `/trip/${trip.id}`;
        try {
            const response = await this.patch(endpoint, trip);
            return {
                status: response.status,
                statusText: response.statusText,
                data: response.data
            }
        }
        catch (e: any) {
            const error = e as unknown as AxiosError;
            return {
                status: error.isAxiosError ? error.status! : 400,
                statusText: error.isAxiosError ? error.code! : 'Bad Request',
                data: error.response
            }
        }
    }

    getDriverTrips = async (driverId: number, options?: IPaginationOptions) => {
        const page = options && options.page || 1;
        const take = options && options.take || 20
        const paginate = options && options.paginate ? "true" : "false";
        let endpoint = `/trips/${driverId}?page=${page}&take=${take}&paginate=${paginate}`
        const trips = await this.get(endpoint);
        return trips;
    }
    getAvailableDrivers = async (request?: IRequest): Promise<User[]> => {
        let availableDrivers: User[] = []
        try {
            const axios = await this.get('/drivers');
            console.log("🚀 ~ ApiClient ~ getAvailableDrivers= ~ axios:", axios)
            if (axios.status == 200) {
                const drivers = axios.data;
                // if (request && request?.type > 0) {
                    availableDrivers = drivers.filter((driver: User) => driver.busy != 3)
                // }
                // else {
                //     availableDrivers = drivers.filter((driver: User) => driver.busy == 0)
                // }
            }
        }
        catch (e: any) {
            return availableDrivers;
        }
        console.log("drivers:",availableDrivers)
        return availableDrivers;
    }
    getNotifications = async (userId: number, options?: IPaginationOptions) => {
        const page = options && options.page || 1;
        const take = options && options.take || 20
        const notifications = await this.get(`/notifications/${userId}?page=${page}&take=${take}`);
        return notifications;
    }
    getUnreadNotifications = async (userId: number) => {
        const notifications = await this.get(`/notifications-unread/${userId}`);
        return notifications;
    }
    getActiveProjects = async () => {
        const projects = await this.get(`/projects-active`);
        return projects;
    }
    getAllProjects = async () => {
        const projects = await this.get(`/projects`);
        return projects;
    }
    getAllFundcodes = async () => {
        const fundcodes = await this.get(`/fundcodes`);
        return fundcodes;
    }
    getActiveFundcodes = async () => {
        const fundcodes = await this.get(`/fundcodes-active`);
        return fundcodes;
    }
    getProjectFundcodes = async (projectCode: string) => {
        const fundcodes = await this.get(`/fundcodes/${projectCode}`);
        return fundcodes;
    }
    getLastRequestOnVehicle = async (vehicle: string, request_type: number) => {
        const axios = await this.get(`/last_request/${vehicle}?type=${request_type}`)
        return axios;
    }
    getVehicleHistory = async (vehicle: string, requestType: number, requestId?: number,) => {
        console.log("🚀 ~ ApiClient ~ getVehicleHistory= ~ requestType:", requestType);
        let endpoint = `/history/${vehicle}?type=${requestType}`;
        if (requestId) endpoint = `${endpoint}&rid=${requestId}`
        const axios = await this.get(endpoint);
        return axios;
    }
    getPositions = async () => {
        const axios = await this.get('/positions');
        return axios;
    }
    getDepartments = async () => {
        const axios = await this.get('/departments');
        return axios;
    }

    getVehicleTripLog = async (vehicle: string) => {
        const axios = await this.get(`/trips/vehicle/${vehicle}`)
        return axios
    }

    //get suppliers
    getSuppliers = async (data: any): Promise<ApiResponse> => {
        const endpoint = data.location ? `/suppliers?location=${data.location}` : `/suppliers`;
        try {
            const response = await this.get(endpoint);
            return {
                status: response.status,
                statusText: response.statusText,
                data: response.data
            }
        }
        catch (e: any) {
            const error = e as unknown as AxiosError;
            return {
                status: error.isAxiosError ? error.status! : 400,
                statusText: error.isAxiosError ? error.code! : 'Bad Request',
                data: error.response
            }
        }
    }


    //get active suppliers
    getAllSuppliers = async (): Promise<ApiResponse> => {
        const endpoint = `/suppliers`;
        try {
            const response = await this.get(endpoint);
            return {
                status: response.status,
                statusText: response.statusText,
                data: response.data.suppliers
            }
        }
        catch (e: any) {
            const error = e as unknown as AxiosError;
            return {
                status: error.isAxiosError ? error.status! : 400,
                statusText: error.isAxiosError ? error.code! : 'Bad Request',
                data: []
            }
        }
    }
    updateSupplier = async (data: any): Promise<ApiResponse> => {
        try {
            const axios = await this.put(`/suppliers/${data.id}`, data)
            return {
                status: axios.status,
                statusText: axios.statusText,
                data: axios.data
            }
        }
        catch (error: any) {
            return {
                status: error.isAxiosError ? error.status! : 400,
                statusText: error.isAxiosError ? error.code! : 'Bad Request',
                data: error.response
            }
        }
    }
    createSupplier = async (data: any): Promise<ApiResponse> => {
        try {
            const axios = await this.post(`/suppliers`, data)
            return {
                status: axios.status,
                statusText: axios.statusText,
                data: axios.data
            }
        }
        catch (error: any) {
            return {
                status: error.isAxiosError ? error.status! : 400,
                statusText: error.isAxiosError ? error.code! : 'Bad Request',
                data: error.response
            }
        }
    }
    updateDepartment = async (data: any): Promise<ApiResponse> => {
        try {
            const axios = await this.put(`/departments/${data.id}`, data)
            return {
                status: axios.status,
                statusText: axios.statusText,
                data: axios.data
            }
        }
        catch (error: any) {
            return {
                status: error.isAxiosError ? error.status! : 400,
                statusText: error.isAxiosError ? error.code! : 'Bad Request',
                data: error.response
            }
        }
    }
    createDepartment = async (data: any): Promise<ApiResponse> => {
        try {
            const axios = await this.post(`/departments`, data)
            return {
                status: axios.status,
                statusText: axios.statusText,
                data: axios.data
            }
        }
        catch (error: any) {
            return {
                status: error.isAxiosError ? error.status! : 400,
                statusText: error.isAxiosError ? error.code! : 'Bad Request',
                data: error.response
            }
        }
    }
    createProject = async (data: any): Promise<ApiResponse> => {
        try {
            const axios = await this.post(`/projects`, data)
            return {
                status: axios.status,
                statusText: axios.statusText,
                data: axios.data
            }
        }
        catch (error: any) {
            return {
                status: error.isAxiosError ? error.status! : 400,
                statusText: error.isAxiosError ? error.code! : 'Bad Request',
                data: error.response
            }
        }
    }
    updateProject = async (data: any): Promise<ApiResponse> => {
        try {
            const axios = await this.put(`/projects/${data.id}`, data)
            return {
                status: axios.status,
                statusText: axios.statusText,
                data: axios.data
            }
        }
        catch (error: any) {
            return {
                status: error.isAxiosError ? error.status! : 400,
                statusText: error.isAxiosError ? error.code! : 'Bad Request',
                data: error.response
            }
        }
    }
    updateFundcode = async (data: any): Promise<ApiResponse> => {

        try {
            const axios = await this.put(`/fundcodes/${data.id}`, data)
            return {
                status: axios.status,
                statusText: axios.statusText,
                data: axios.data
            }
        }
        catch (error: any) {
            return {
                status: error.isAxiosError ? error.status! : 400,
                statusText: error.isAxiosError ? error.code! : 'Bad Request',
                data: error.response
            }
        }
    }
    createFundcode = async (data: any): Promise<ApiResponse> => {
        const expiration = Date.parse(data.expiration) / 1000;
        delete data.expiration
        const postData = {
            ...data,
            expiration: expiration
        }
        console.log("🚀 ~ ApiClient ~ createFundcode= ~ postData:", postData)
        try {
            const axios = await this.post(`/fundcodes`, postData)
            return {
                status: axios.status,
                statusText: axios.statusText,
                data: axios.data
            }
        }
        catch (error: any) {
            return {
                status: error.isAxiosError ? error.status! : 400,
                statusText: error.isAxiosError ? error.code! : 'Bad Request',
                data: error.response
            }
        }
    }
}
