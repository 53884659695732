import { useLocation, useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../components/sidebar";
import { Fundcode, IProjectDetail, Vehicle } from "../../utils/types";
import { dateFormat, getQueryString, getStoredUserData, getUserSession, isAdmin, isAuthenticated, makeYears } from "../../utils/utils";
import { ApiClient } from "../../utils/apiclient";
import { ChangeEvent, useEffect, useState } from "react";
import Spinner from "../../components/spinner";
import TopStrip from "../../components/top_strip";
import { getCityNames } from 'postcodes-tz'
import { Formik } from "formik";
import * as Yup from 'yup'
import { ToastContainer, toast } from 'react-toastify';

const VehicleEditForm = (props: any) => {
    const session = getUserSession()
    const apiClient = new ApiClient(session?.accessToken);
    const userData = getStoredUserData();
    const location = useLocation();
    const parsedVehicle: Vehicle = location?.state?.vehicle ? location.state.vehicle : null;
    console.log("🚀 ~ VehicleEditForm ~ parsedVehicle:", parsedVehicle)
    const target = `${window.location.pathname}${getQueryString(window.location.href)}`;
    const params = useParams();
    const vehicleId = params.vehicleId;
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [projects, setProjects] = useState<IProjectDetail[]>([])
    const [fundcodes, setFundcodes] = useState<Fundcode[]>([]);
    const [fFundcodes, setFFundcodes] = useState<Fundcode[]>([])
    const [vehicle,setVehicle] = useState<Vehicle>(parsedVehicle)
    const [hasFocus,setHasFocus] = useState<boolean>(false)


    const formValidationSchema = Yup.object({
        pnumber: Yup.string().required('Registration number required'),
        brand: Yup.string().required('Provide vehicle manufacturer'),
        model: Yup.string().required('Please enter the vehicle model'),
        year: Yup.number().required('Year of manufacturing'),
        project: Yup.string().required('Please select a project'),
        fundcode: Yup.string().required('Please provide a fundcode'),
        location: Yup.string().required("Please select a location"),
        chassis_no: Yup.string().required("Please enter chassis number or VIN"),
        a_date: Yup.date().required()
    })
    const handleCancel = () => {
        navigate(-1)
    }
   
    const getProjects = async () => {
        const axios = await apiClient.getActiveProjects();
        if (axios.data) {
            setProjects(axios.data.projects)
        }
    }
    const getFundcodes = async () => {
        const axios = await apiClient.getActiveFundcodes();
        if (axios.data) {
            setFundcodes(axios.data.fundcodes)
            setFFundcodes(axios.data.fundcodes)
        }
    }

    const handleProjectChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const p = e.currentTarget.value;
        setFFundcodes(fundcodes.filter((f: Fundcode) => f.project == p))
    }

    const formHandler = async (values: any) => {
        console.log("🚀 ~ formHandler ~ values:", values)
        setIsLoading(true);
        values.a_date = values.a_date ? Date.parse(values.a_date)/1000: vehicle.a_date;
        values.id = vehicle.id
        console.log("🚀 ~ formHandler ~ values:", values)
        const axios = await apiClient.updateVehicle(values);
        console.log("🚀 ~ formHandler ~ axios:", axios)
        toast.info(axios.data, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        })
        setIsLoading(false)
        setTimeout(()=>{
            navigate('/vehicles')
        },2000)
    }
    const getVehicleDetails =async (vehicleId:number)=>{
        const vehicle = await apiClient.getVehicleDetails(vehicleId)
        console.log("🚀 ~ getVehicleDetails ~ vehicle:", vehicle)
        if(vehicle.status == 200){
            setVehicle(vehicle.data.vehicle)
        }
    }
    const handleFocus = (e:React.FocusEvent<HTMLInputElement>)=>{
        setHasFocus(true)
    }
    const handleBlur = (e:React.FocusEvent<HTMLInputElement>)=>{
        setHasFocus(false)
    }
    // getVehicleHisotory()
    useEffect(() => {
        if(isAuthenticated()){
            if(userData){
                getProjects();
                getFundcodes()
                if(!vehicle){
                    getVehicleDetails(Number(vehicleId))
                }
                
            }
        }
       else navigate('/login',{state:{targetPath: target}})
    }, [])
    return (<>
        <div className="w-full flex justify-end items-center bg-gray-100 pe-4">
            <TopStrip user={userData} />
        </div>
        <main className="w-full flex bg-white min-h-full">
            <div className="md:w-2/12 flex flex-col items-start text-center justify-center">
                <Sidebar notificationCount={[].length} user={userData} active={3} />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 f
            </div>
            <ToastContainer />
            <div className="md:w-9/12 mx-auto space-y-4 mt-2">

                <div className="w-full">
                    <div className="w-full flex items-center justify-between p2-4">
                        <p className="text-2xl font-bold">Edit Vehicle Info</p>
                    </div>
                    {isLoading ? <Spinner className="spinner-md mt-6"/>:
                    <div className="w-full flex justify-between items-start py-6 mx-auto border mt-6 h-full">

                        <Formik validationSchema={formValidationSchema} initialValues={{ in_use:vehicle.in_use,donor:vehicle.donor,a_date:dateFormat(vehicle?.a_date!),chassis_no: vehicle?.chassis_no, pnumber: vehicle?.pnumber, brand: vehicle?.brand, model: vehicle?.model, year: vehicle?.year, mileage: vehicle?.mileage, current_mileage: vehicle?.current_mileage, project: vehicle?.project, location: vehicle?.location, fundcode: vehicle?.fundcode,a_cost:vehicle.a_cost }} onSubmit={formHandler}>
                            {({ handleSubmit, handleChange, values, errors, touched }) => (
                                <form
                                    name="new-user-form"
                                    id="new-user-form" className="mt-4 mx-auto"
                                    onSubmit={handleSubmit}
                                >
                                    <div className="w-full py-6 mx-auto">
                                        <div className="space-x-4 flex justify-between items-start">
                                            <div className="text-start mb-4 w-6/12">
                                                <label className="text-gray-500">Registration</label>
                                                <input type="text" className="form-control" name="pnumber" id="pnumber" onChange={handleChange} placeholder="T335DPA" defaultValue={values.pnumber}/>
                                                {errors && errors.pnumber ? <small className="text-sm text-red-500">{errors.pnumber}</small> : null}
                                            </div>
                                            <div className="text-start mb-4 w-6/12">
                                                <label className="text-gray-500">Chassis Number</label>
                                                <input type="text" className="form-control" id="chassis_no" name="chassis_no" placeholder="Enter chassis number" onChange={handleChange} defaultValue={values.chassis_no}/>
                                                {errors && errors.chassis_no ? <span className="text-sm text-red-500">{errors.chassis_no}</span> : null}
                                            </div>
                                        </div>

                                        <div className="space-x-4 flex justify-between items-start">
                                            <div className="text-start mb-4 w-6/12">
                                                <label className="text-gray-500">Make</label>
                                                <input type="text" className="form-control" name="brand" id="brand" onChange={handleChange} placeholder="Toyota"  defaultValue={values.brand}/>
                                                {errors && errors.brand ? <span className="text-sm text-red-500">{errors.brand}</span> : null}
                                            </div>
                                            <div className="text-start mb-4 w-6/12">
                                                <label className="text-gray-500">Model</label>
                                                <input type="text" className="form-control" id="model" name="model" placeholder="Landcruiser" onChange={handleChange}  defaultValue={values.model}/>
                                                {errors && errors.model ? <span className="text-sm text-red-500">{errors.model}</span> : null}
                                            </div>
                                        </div>
                                        <div className="space-x-4 flex justify-between items-start">
                                            <div className="text-start mb-4 w-6/12">
                                                <label className="text-gray-500">Year of Manufacture</label>
                                                <select id="year" name="year" className="form-control" onChange={handleChange}  defaultValue={values.year}><option>--</option>
                                                    {makeYears(30).map((y:number) => {
                                                            return <option key={y} value={y}>{y}</option>
                                                        
                                                    })}
                                                </select>
                                                {errors && errors.year ? <span className="text-sm text-red-500">{errors.year}</span> : null}
                                            </div>
                                            <div className="text-start mb-4 w-6/12">
                                                <label className="text-gray-500">Initial Cost (Tsh)</label>
                                                <input type="number" className="form-control" id="a_cost" name="a_cost" placeholder="Enter cost" onChange={handleChange}  defaultValue={values.a_cost}/>
                                            </div>
                                        </div>
                                        <div className="space-x-4 flex justify-between items-start">
                                            <div className="text-start mb-4 w-6/12">
                                                <label className="text-gray-500">Current Mileage (Km)</label>
                                                <input type="number" className="form-control" id="current_mileage" name="current_mileage" placeholder="Vehicle Mileage"  onChange={handleChange} defaultValue={values.current_mileage}/>
                                                {errors && errors.current_mileage ? <span className="text-sm text-red-500">{errors.current_mileage}</span> : null}
                                            </div>
                                            <div className="text-start mb-4 w-6/12">
                                                <label className="text-gray-500">Initial Mileage (Km)</label>
                                                <input type="number" className="form-control" id="mileage" name="mileage" placeholder="Enter initial mileage" onChange={handleChange}  defaultValue={values.mileage}/>
                                            </div>
                                        </div>
                                        <div className="space-x-4 flex justify-between items-start">
                                            <div className="text-start mb-4 w-6/12">
                                                <label className="text-gray-500">Donor</label>
                                                <input type="text" className="form-control" id="donor" name="donor" placeholder="Enter donor" onChange={handleChange}  defaultValue={values.donor}/>
                                            </div>
                                            <div className="text-start mb-4 w-6/12">
                                                <label className="text-gray-500">Current Location</label>
                                                <select  defaultValue={values.location}
                                                    className="form-control "
                                                    name="location"
                                                    id="location"
                                                    onChange={handleChange}
                                                >
                                                    {getCityNames("asc").map((c: string) => {
                                                        return <option key={c}>{c}</option>;
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="space-x-4 flex justify-between items-start">
                                            <div className="text-start mb-4 w-6/12">
                                                <label>Select Project</label>
                                                <select value={values.project}
                                                    id="project"
                                                    name="project"
                                                    className="form-control" onChange={(e) => {
                                                        handleChange(e);
                                                        handleProjectChange(e);
                                                    }}><option>---</option>
                                                    {projects.map((p: IProjectDetail) => {
                                                        return <option key={p.id} value={p.code}>{p.code}</option>
                                                    })}</select>

                                                {errors && errors.project ? <span className="text-sm text-red-500">{errors.project}</span> : null}
                                            </div>
                                            <div className="text-start mb-4 w-6/12">
                                                <label className="text-gray-500">Fund Code</label>
                                                <select  value={values.fundcode}
                                                    id="fundcode"
                                                    name="fundcode"
                                                    className="form-control" onChange={handleChange}><option>---</option>
                                                    {fFundcodes.map((p: Fundcode) => {
                                                        return <option key={p.id} value={p.code}>{p.code}</option>
                                                    })}</select>
                                            </div>
                                        </div>
                                        <div className="space-x-4 flex justify-between items-start">
                                            <div className="text-start mb-4 w-6/12">
                                                <label className="text-gray-500">Date Acquired</label>
                                                <input type="date" className="form-control" name="a_date" id="a_date" onChange={handleChange}  defaultValue={values.a_date} onFocus={handleBlur} onBlur={handleFocus}/>
                                                {errors && errors.a_date ? <small className="text-sm text-red-500">{errors.a_date}</small> : null}
                                            </div>
                                            {isAdmin(userData) ? <div className="text-start mb-4 w-6/12">
                                                <label className="text-gray-500">Status</label>
                                                <select className="form-control" id="in_use" name="in_use" onChange={handleChange} defaultValue={vehicle?.in_use}>
                                                    <option>--</option>
                                                    <option value={0}>Available</option>
                                                    <option value={1}>In Use</option>
                                                    <option value={3}>Disposed</option>
                                                </select>
                                                {errors && errors.in_use ? <span className="text-sm text-red-500">{errors.in_use}</span> : null}
                                            </div>:null}
                                        </div>
                                        <div className="space-x-4 flex justify-between items-start">
                                            {isLoading ? <Spinner className="spinner-sm-white my-2" /> :
                                                <button
                                                    className="text-white bg-accent"
                                                    type="submit"
                                                    id="submitUser"
                                                >Save</button>}
                                            <button
                                                id="cancel-user"
                                                className="border border-accent text-accent"
                                                onClick={handleCancel}
                                            >
                                                CANCEL
                                            </button>
                                        </div>
                                    </div>
                                </form>)}
                        </Formik>
                    </div>}

                </div>
            </div>
        </main>
    </>)
}
export default VehicleEditForm;