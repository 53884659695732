'use client'
import { VEHICLE_HEADINGS } from "../../utils/constants";
import TableHeader from "../../components/table_header";
import { Vehicle } from "../../utils/types";
import { useNavigate } from "react-router-dom";
import { formatNumber, getStatusBadge } from "../../utils/utils";
import RequestStatusBadge from "../../components/request_status";


const VehiclesTable = (props: any) => {
  const navigate = useNavigate()
  return (

    <table className="w-full text-left p-2 mx-auto border">
      <TableHeader data={VEHICLE_HEADINGS} />
      <tbody className="w-full text-sm">
        {props.vehicles && props.vehicles.length >0 ? props.vehicles.map((row: Vehicle) => {
          return (
            <tr key={row.id} className="border-b border-b-gray-200 py-8 hover:bg-gray-100 cursor-pointer px-2" onClick={() => { navigate(`/vehicles/view/${row.id}`, { state: { vehicle: row } }) }}>
              <td className="border-bottom px-1 py-2">{row.pnumber}
              </td>
              <td className="border-bottom px-1 py-2">{`${row.brand}, ${row.model}`}
              </td>
              <td className="border-bottom px-1 py-2">{row.year}
              </td>
              <td className="border-bottom px-1 py-2">{formatNumber(row.current_mileage)}</td>
              <td className="border-bottom px-1 py-2">{formatNumber(row?.next_service_mileage)}
              </td>
              <td className="border-bottom px-1 py-2">{row.donor}
              </td>
              <td className="border-bottom px-1 py-2">{row.last_location ? row.last_location : row.location}
              </td>
              <td className="border-bottom px-1 py-2"><RequestStatusBadge status={getStatusBadge(row.in_use,'vehicle')}/>
              </td>
            </tr>
          )
        }):<tr><td className="my-4 text-1xl text-center" colSpan={9}> No Vehicles</td></tr>}
      </tbody>
    </table>
  )

};
export default VehiclesTable;
