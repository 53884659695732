import TableHeader from "../../components/table_header"
import { FUNDCODES_HEADINGS } from "../../utils/constants"
import { Fundcode } from "../../utils/types"
import { dateFormat } from "../../utils/utils"

const FundcodeTable = (props:any)=>{
    const handleClick = (e: React.MouseEvent<HTMLTableRowElement>, fundcode: Fundcode)=>{
          props.handleSelect(fundcode)
        }
    return (
    
        <table className="w-full text-left p-2 mx-auto border">
          <TableHeader data={FUNDCODES_HEADINGS} />
          <tbody className="w-full text-sm">
            {props.data && props.data.map((row:Fundcode) => {
              return (
                <tr key={row.id} className="text-sm border-b border-b-gray-200 py-8 hover:bg-gray-100 cursor-pointer px-2 items-start" onClick={(e)=>{handleClick(e,row)}}>
                  <td className="border-bottom px-1 py-2">{row.code}
                  </td>
                  <td className="border-bottom px-1 py-2">{`${row.project}`}
                  </td>
                  <td className="border-bottom px-1 py-2">{`${dateFormat(row.expiration)}`}</td>
                  <td className="border-bottom px-1 py-2">{row.activities}
                  </td>
                  <td className="border-bottom px-1 py-2">{row.expiration * 1000 < Date.now() ? 'Expired': 'Active'}
                  </td>
                  
                </tr>
              )
            })}
          </tbody>
        </table>
        )
}
export default FundcodeTable