import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import { useState, FormEvent } from "react";
import { IDepartmentDetail, IUser } from "../../utils/types";
import { dateFormat } from "../../utils/utils";

const ProjectForm = (props:any)=>{
    const [open,setOpen] = useState<boolean>(props.state)
    const [data,setData] = useState<any>(props.data)

    const handleClose = ()=>{
        setOpen(false);
        props.onClose()
    }
 const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    const e = event as unknown as FormEvent<HTMLFormElement>
    const formData = new FormData(e.currentTarget);
    let formJson = Object.fromEntries((formData as any).entries());
    formJson.start_date = Date.parse(formJson.start_date)/1000
    formJson.end_date = Date.parse(formJson.end_date)/1000
    if(data) formJson.id = data.id;
    props.onSubmit(formJson)
    handleClose();
  }
  const handleFocus=(e:React.FocusEvent)=>{
    e.preventDefault();
    const target_id = e.currentTarget.id;
    const target = document.getElementById(target_id);
    target?.setAttribute('type','date');

  }
  const handleBlur=(e:React.FocusEvent<HTMLInputElement>)=>{
    e.preventDefault();
    const target = e.currentTarget;
    const val = e.currentTarget.value;
    target?.setAttribute('type','text');
    target.value = val

  }
   
    return (<>
       <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: handleFormSubmit
        }}
      >
        <DialogTitle>{data ? 'Update Project': 'Add New Project'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Complete this form to ${data ? 'update' : 'create'} a new project`}
          </DialogContentText>
          <label>Project Name</label>
          <input
            autoFocus
            required
            id="name"
            name="name"
            type="text"
            defaultValue={data?.name}
            className="form-control"
            placeholder="Enter project name"
          />
           <label>Project Code</label>
          <input
            autoFocus
            required
            id="code"
            name="code"
            type="text"
            defaultValue={data?.code}
            className="form-control"
            placeholder="Enter project code"
          />
           <label>Start Date</label>
          <input
            required
            onFocus={handleFocus}
            onBlur={handleBlur}
            id="start_date"
            name="start_date"
            type={data?.start_date ? 'text':'date'}
            defaultValue={dateFormat(data?.start_date)}
            className="form-control"
          />
           <label>Finish Date</label>
          <input
            required
            onFocus={handleFocus}
            onBlur={handleBlur}
            id="end_date"
            name="end_date"
            type={data?.end_date ? 'text':'date'}
            defaultValue={dateFormat(data?.end_date)}
            className="form-control" />
          <label>Donor</label>
          <input
            autoFocus
            required
            id="donor"
            name="donor"
            type="text"
            defaultValue={data?.donor}
            placeholder="Enter donnor"
            className="form-control"
          />
          <label>Select Department</label>
           <select
            required
            id="department"
            name="department"
            defaultValue={data?.department}
            className="form-control"><option>--</option>
            {props.departments.map((c:IDepartmentDetail)=>{
                return <option key={c.id} value={c.id}>{`${c.code}- ${c.region}`}</option>
            })}
            </select>
            <label>Choose Project Coordinator</label>
           <select
            required
            id="coordinator"
            name="coordinator"
            defaultValue={data?.coordinator}
            className="form-control"><option>--</option>
            {props.coordinators.map((c:IUser)=>{
                return <option key={c.id} value={c.id}>{`${c.fname} ${c.lname}`}</option>
            })}
            </select>
        </DialogContent>
        <DialogActions>
          <button onClick={handleClose} className="text-accent">Cancel</button>
          <button type="submit" className="bg-green-700">Save</button>
        </DialogActions>
      </Dialog>
    </>);
}
export default ProjectForm