'use client'
import { USERS_HEADINGS } from "../../utils/constants";
import TableHeader from "../../components/table_header";
import { User } from "../../utils/types";
import { useNavigate } from "react-router-dom";
import { getStatusBadge } from "../../utils/utils";
import RequestStatusBadge from "../../components/request_status";


const UserListItem = (props: any) => {
 const navigate = useNavigate()
  return (
    
      <table className="w-full text-left p-2 mx-auto border">
        <TableHeader data={USERS_HEADINGS} />
        <tbody className="w-full text-sm">
          {props.users && props.users.length > 0 ? props.users.map((row:User) => {
            return (
              <tr key={row.id} className="border-b border-b-gray-200 py-8 hover:bg-gray-100 cursor-pointer" onClick={()=>{navigate(`/users/view/${row.id}`,{state:{user:row}})}}>
                <td className="border-bottom px-1 py-2">{row.username}
                </td>
                <td className="border-bottom px-1 py-2">{row.email}
                </td>
                <td className="border-bottom px-1 py-2">{row.phone}
                </td>
                <td className="border-bottom px-1 py-2">{`${row.fname} ${row.lname}`}
                </td>
                <td className="border-bottom px-1 py-2">{row.position_detail?.value}
                </td>
                <td className="border-bottom px-1 py-2">{row.dept_code}
                </td>
                <td className="border-bottom px-1 py-2">{row.location}
                </td>
                <td className="border-bottom px-1 py-2"><RequestStatusBadge status={getStatusBadge(row.busy,'user')}/>
                </td>
              </tr>
            )
          }):<tr><td className="my-4 text-1xl text-center" colSpan={9}> No Users</td></tr>}
        </tbody>
      </table>
      )

};
export default UserListItem;
