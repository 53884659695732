'use client'
import { MouseEvent } from "react";
import { REQUESTS_HEADINGS, REQUESTS_STATUS_ID } from "../../utils/constants";
import { IFuelRequest, IMaintenanceRequest, IRequest } from "../../utils/types";
import { canApprove, canAuthorize, canEdit, dateFormat, getRequestTypeText } from "../../utils/utils";
import TableHeader from "../../components/table_header";
import Tooltip from "@material-ui/core/tooltip"
import CloseButton from "@material-ui/icons/CloseRounded"
import ViewIcon from "@material-ui/icons/Visibility"
import ApproveIcon from "@material-ui/icons/CheckCircle"

const RequestTable = (props: any) => {

    const handleClick = (event: MouseEvent, item: IRequest | IMaintenanceRequest | IFuelRequest, type: string) => {
       props.onSelect(item,type)

    };

    return (
        <div className="w-full text-textDefault mt-4">
            
            <table className="table-fixed w-full text-left p-2 mx-auto border">
                <TableHeader data={REQUESTS_HEADINGS} />

                <tbody className="w-full text-sm">
                    {props.data && props.data.length > 0 ? props.data.map((row: IRequest) => {
                        return (
                            <tr key={row.id} className="border-b border-b-gray-200 py-8 hover:bg-gray-100 cursor-pointer" >
                                <td className="border-bottom px-1 py-2">{dateFormat(row.date_created)}
                                </td>
                                {/* <td className="border-bottom px-1 py-2">{dateFormat(row.start_time)}
                                </td> */}
                                <td className="border-bottom px-1 py-2">{getRequestTypeText(row.type)}
                                </td>
                                <td className="border-bottom px-1 py-2">{`${row.nameOfRequestor}`}
                                </td>
                                <td className="border-bottom px-1 py-2">{row.statusText}
                                </td>
                                <td className="border-bottom px-1 py-2">{row.project.includes(",") ? row.project.split(",")[0] : row.project}
                                </td>
                                <td className="border-bottom px-1 py-2">{row.fundcode.includes(",") ? row.fundcode.split(",")[0] : row.fundcode}
                                </td>
                                <td className="border-bottom px-1 py-2 flex justify-between items-center w-full space-x-3 ">
                                    <Tooltip title="View">
                                        <ViewIcon className="cursor-pointer" onClick={(e) => handleClick(e, row, 'view')} />
                                    </Tooltip>
                                    {(canApprove(props.user, row) || canAuthorize(props.user, row)) && row.status == REQUESTS_STATUS_ID.PENDING ? <Tooltip title="Approve"><ApproveIcon className="text-green-700 cursor-pointer" onClick={(e) => handleClick(e, row, 'approve')} /></Tooltip> : <ApproveIcon className="text-gray-500 cursor-not-allowed" />}
                                    {canEdit(props.user, row) ?
                                        <Tooltip title="Reject">
                                            <CloseButton className="text-red-700  cursor-pointer" onClick={(e) => handleClick(e, row, 'reject')} />
                                        </Tooltip>
                                        : <CloseButton className="text-gray-500  cursor-not-allowed" />
                                    }
                                </td>
                            </tr>
                        )
                    }):<tr><td className="my-4 text-1xl text-center" colSpan={8}> No Requests</td></tr>}
                </tbody>
            </table>


        </div>)
}
export default RequestTable;
